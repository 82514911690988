import React from 'react';

interface ChatModalProps {
	show: boolean;
	onClose: () => void;
	url: string;
}

const ChatModal: React.FC<ChatModalProps> = ({ show, onClose, url }) => {
	if (!show) return null;

	return (
		<div className='chat-modal' style={chatModalStyles}>
			<button onClick={onClose} style={closeButtonStyles}>
				×
			</button>
			<iframe src={url} title='External Page' style={iframeStyles} />
		</div>
	);
};

// Estilos en línea para el "chat modal"
const chatModalStyles: React.CSSProperties = {
	position: 'fixed',
	bottom: '10px',
	right: '20px',
	width: '25%',
	height: '98%',
	backgroundColor: '#fff',
	borderRadius: '10px',
	boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
	overflow: 'hidden',
	zIndex: 1000,
};

const iframeStyles: React.CSSProperties = {
	width: '100%',
	height: '100%',
	border: 'none',
};

const closeButtonStyles: React.CSSProperties = {
	position: 'absolute',
	top: '10px',
	right: '10px',
	background: 'none',
	border: 'none',
	fontSize: '18px',
	cursor: 'pointer',
};

export default ChatModal;
